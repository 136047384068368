import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <div className="top">
          <h1>Lillejord.no</h1>
          <a href="mailto:espen@lillejord.no">espen@lillejord.no</a>
        </div>
      </div>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}

export default App;
